<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5> Personnel Settlement Report ({{count_all | number_format(null, 0)}}) </h5>
			</CCol>
	     		 <CCol lg="6"> 
	     		 	<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info">Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <!-- <CButton  v-if="config.getPermission('personnel_settlement_reports').download" :disabled="all_isloading"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary" 
							@click="download" class="float-lg-right">
							<i class="fa fa-download"></i> Download
					</CButton> -->
					<CButton 
						v-if="config.getPermission('personnel_settlement_reports').download" :disabled="all_isloading"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary"
						class="float-lg-right"
						@click="() => { showModalDownloadFilter = true }">
						<i class="fa fa-download"></i> Download
					</CButton>
			    </CCol>
		</CRow>
		<CRow class="mb-3"> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search" :show="showCollapse"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<CRow>			
	      	<CCol lg="12" >
				   <div class="table-responsive-sm table-responsive-md table-responsive-lg "	>
	      		
				      	<CDataTable 
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							striped
							outlined
							:loading="all_isloading"
					    >  
						<template #status="{item}">
								 <td style="padding: 5px; width: 100px;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="'success'">{{item.status}}</CBadge>
         						 </td>
					      	</template>
						
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				   
				  
				   </div>
			</CCol>
	    </CRow>
	     
        
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './personnel_settlement_report_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../style.css';

import moment from 'moment';
import downloadFilter from './personnel_settlement_filter_report.vue';
export default {
	mounted(){  
		this.getData(); 
		
	},
	data(){
		return{
			config,
			
			formModal: false,
			showViewModal: false,
			count_all: 0,
			
			dataList: {
				data: [
					{
						booking_no: "",
						formatted_delivery_date: "",
						depot: "",
						customer_name:"",
						origin: "",
						destination: "",
						name: "",
						personnel_type: "",
						personnel_rate: "",
						delivery_date : "",
						status: ""
					}
				]
			}, 
		
			depotName: "", 
			fields: [
				{
					key: 'booking_no', 
					label: 'Booking No.',
					
				},
				{
					key: 'customer_name', 
					label: 'Customer'
				}, 
				{
					key: 'delivery_date', 
					label: 'Delivery Date'
				}, 
			
				{
					key: 'depot', 
					label: 'Depot'
				},  
				
				{
					key: 'origin', 
					label: 'Origin'
				}, 
                
                	{
					key: 'destination', 
					label: 'Destination'
				}, 
			
				{
					key: 'name', 
					label: 'Personnel',
					sorter : true
				}, 
				
				{
					key: 'personnel_type', 
					label: 'Personnel Type'
				}, 
				{
					key: 'personnel_rate', 
					label: 'Personnel Rate'
				},
				{
					key: 'status', 
					label: 'Status'
				}
			],
			currentPage: 1, 
			

			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				booking_no: "",
	            destination_id: "",
				customer_id:"",
	            asset_type: "",
	            origin_id: "",
	            delivery_date_to: moment(new Date())._d,
                delivery_date_from: moment(new Date()).subtract(2, 'weeks')._d, 
	            client_ref_no: "",
	            created_at_from: "",
	            created_at_to: "",
				status : ""
	           
			},
			
			all_isloading: true,
			user_details: JSON.parse(window.localStorage.getItem('user_data')),
			showModalDownloadFilter:false,
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, downloadFilter},
	methods: {
	
		reloadData(){
			this.getData(); 
			
		},
		
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

		
	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData(); 
			
	    }, 

	    getData(){  
	    	this.all_isloading = true
			
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from).format("YYYY-MM-DD"); 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to).format("YYYY-MM-DD");
	    		// delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	} 
	    	

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}
				
	    	axios.get(`${config.api_path}/personnel/settlement-report/list`,{
				params : {
					booking_no :this.filter.booking_no,
					destination_id : this.filter.destination_id,
					name : this.filter.personnel_name,
					personnel_type : this.filter.personnel_type,
					customer_id : this.filter.customer_id,
					origin_id : this.filter.origin_id,
					delivery_date_from : delivery_date_from,
					delivery_date_to : delivery_date_to,
					page : this.currentPage,
					depot_id : this.filter.depot_id,
					limit : 20,
					company_id : this.user_details.company_id
				}
			})
	    	.then(response => {
	    		this.all_isloading = false;
				
	    		this.count_all = response.data.data.total;
	    		this.dataList = response.data.data;
				this.dataList.data = this.dataList.data.map(item => {
					item.personnel_rate = item.personnel_rate ?? '';
					item.delivery_date = (item.delivery_date) ? moment.unix(item.delivery_date).format('MM/DD/YYYY') : '';
					item.status = item.status ? item.status.toUpperCase() : ''
					return item;
				})

				console.log(this.dataList)
	    	})
	    },

	  
	    download(){
			let data = [], param = [];

	
			let {delivery_date_from, delivery_date_to, personnel_type,depot_id,origin_id} = this.filter;
			
			if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from).format("YYYY-MM-DD"); 
	    	}

	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to).format("YYYY-MM-DD");
	    		// delivery_date_to = delivery_date_to + 86399; 
	    	}
			let worksheet_title = `${moment(delivery_date_from).format("MMMDD,YYYY")}-${moment(delivery_date_to).format("MMMDD,YYYY")}`;

			data = {
				depot_id : this.filter.depot_id,
				customer_id: this.filter.customer_id,
				origin_id : this.filter.origin_id,
				delivery_date_from,
				delivery_date_to,
				personnel_type,
				worksheet_title,
				company_id : this.user_details.company_id
			}

			this.$showLoading(true)
	    	
	    	axios.post(`${config.api_path}/personnel/settlement-report/generate`,data)
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				 
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
				
	    	}).catch(err => {
				this.$showLoading(false)
			})
	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
 	}
}
</script>
