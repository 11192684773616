<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="" v-model="booking_no" placeholder="Booking No."/>
        </CCol>
        <CCol lg="3">
          <CInput size="" v-model="personnel_name" placeholder="Personnel Name"/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="destinationList.data"
            :reduce="item => item.id"
            v-model="destination_id"
            placeholder="Destination"
          > 
          </v-select> 
        </CCol>   
      
      </CRow>
      <CRow class="mb-0">

        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select> 
          
          
        </CCol>
        <CCol lg="3">
            <v-select 
              label="type" 
              :options="[{type : 'All', id: 'all'},{type: 'Driver', id: 'driver'},{type: 'Helper', id: 'helper'}]"
              :reduce="item => item.id"
              v-model="personnel_type"
              placeholder="Personnel Type"
            > 
            </v-select> 
        </CCol>
       
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select> 
        </CCol> 
         </CRow>
         <CRow class="mb-0 mt-3">
        <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from"/> 
        </CCol>
        <CCol lg="3"> 
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol>
        
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
      <br/>
      
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import Datepicker from 'vuejs-datepicker';
  import moment from 'moment';
  import Swal from 'sweetalert2';
    export default {
        name: "PersonnelTypeSearch",
        data() {
          return {
            personnel_type: "",
            personnel_name: "",
            booking_no: "",
            customer_id:"",
            depot_id: "",
            destination_id: "",
            origin_id: "",
            delivery_date_to: moment(new Date())._d,
            delivery_date_from: moment(new Date()).subtract(2, 'weeks')._d, 
            depotList: {
              data: []
            },
            destinationList: {
              data: []
            },
            customerList: {
              data: []
            },
            originList: {
              data: []
            },
            list_loaded: false
            
          }
        },
      mounted() {
        

      },
      props: {
        show: false
      },
      watch: {
        async show(val) {
          if(val) {
            if(!this.list_loaded) {
              await this.getDepotList();
              await this.getDestinationList();
              await this.getOriginList();
              await this.getCustomer();
              this.list_loaded = true;
            }
            
          }
        }
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            booking_no: this.booking_no,
            customer_id: this.customer_id,
            depot_id:  this.depot_id,
            destination_id:  this.destination_id,
            asset_type:  this.asset_type,
            origin_id:  this.origin_id,
            delivery_date_to:  this.delivery_date_to,
            delivery_date_from:  this.delivery_date_from,
            personnel_type : this.personnel_type,
            personnel_name : this.personnel_name
          }
        
          if(Math.ceil(moment(this.delivery_date_to).diff(moment(this.delivery_date_from), 'days', true).toFixed(1)) >= 31){
            Swal.fire({
              title: 'Note!',
              text: "Delivery range should not exceed 1 month.",
              icon: 'warning', 
            })
          }
          else{
            this.$emit("depot-search-query", data)
          }
        },
        clearSearch() {
          this.booking_no = ""
          this.depot_id = ""
          this.customer_id=""
          this.destination_id = ""
          this.asset_type = ""
          this.origin_id = ""
          this.delivery_date_to = new Date()
          this.delivery_date_from = moment(new Date()).subtract(2, 'weeks')._d;
          const data = {
            booking_no: this.booking_no,
            depot_id:  this.depot_id,
            customer_id: this.customer_id,
            destination_id:  this.destination_id,
            asset_type:  this.asset_type,
            origin_id:  this.origin_id,
            delivery_date_to: moment(new Date()),
            delivery_date_from: moment(new Date()).subtract(2, 'weeks')._d,
            
          }
          this.personnel_type = null;
          this.personnel_name = null;
          this.$emit("depot-search-query", data)
        },

        getDepotList(){

          axios.get(config.api_path+'/reference/settings-list',{
				    params:{
					setting_type:'depot'
					}
						})
				.then(response => {
					this.depotList = response.data; 
				})
        },
        getCustomer(){

              axios.get(config.api_path+'/reference/customer-list', {
                params: {
                show_all : true
              }
              })
          .then(response => {
            this.customerList = response.data;
            
          })

          },

        getDestinationList(){

          axios.get(config.api_path+'/reference/settings-list',{
			  	params:{
					setting_type:'destination'
					}
						})
				.then(response => {
					this.destinationList = response.data; 
				})

        },

        getOriginList(){

          axios.get(config.api_path+'/reference/settings-list',{
			  	params:{
					setting_type:'origin'
					}
						})
				.then(response => {
					this.originList = response.data; 
				})

        },
	  }
    }
</script>

<style scoped>

</style>
